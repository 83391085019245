<template>
	<!-- 专业管理 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理日报</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addHandle()">新增</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="disabledBatchDelete"
						@click="deleteBatch()">删除</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="13" style="margin-left:20px">
						<el-form-item label="文件名称" class="selFormItem">
							<el-input class="selSearchInput" type="input" v-model="fileName" placeholder="请输入内容"
								clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="selGetDataList()">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<el-row>
			<div>
				<el-card style="margin-top: -25px; height: 550px;" body-style="height:510px; padding: 0;">
					<el-row style="height: 100%; margin-bottom: 10px;">
						<el-col :span="24" style="height: 100%;">
							<div class="box">
								<el-table height="490" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
									ref="dataListTable" class="dataListTable" :data="dataList" header-align="center"
									:cell-style="{ padding: '0px' }" header-cell-class-name="dataListTHeader" border
									style="margin-bottom: 20px;" @selection-change="handleSelectionChange">
									<el-table-column type="selection" width="60" align="center"></el-table-column>
									<el-table-column type="index" label="NO." width="60" align="center">
										<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
										}}</template>
									</el-table-column>
									<el-table-column prop="fileName" label="文件名称" min-width="400" align="left">
										<template slot-scope="scope">
											<el-button v-preventReClick type="text" size="small"
												@click="viewPdf(scope.row.filePath, scope.row.filePathUrl, scope.row.fileName)">{{
													scope.row.fileName }}</el-button>
										</template>
									</el-table-column>
									<el-table-column prop="createTime" label="日期" align="center" width="150px">
									</el-table-column>
									<el-table-column prop="createMan" label="上传人" align="center" width="150px">
									</el-table-column>
									<el-table-column prop="remark" label="备注" align="center" width="300px">
									</el-table-column>
									<el-table-column label="操作" width="150" align="center">
										<template slot-scope="scope">
											<el-button v-preventReClick type="text" size="small"
												@click="updateHandle(scope.row.recId)">更新</el-button>
											<el-button v-preventReClick type="text" size="small"
												v-if="scope.row.filePathUrl != null"
												@click="downloadHandle(scope.row.filePathUrl, scope.row.fileName)">下载</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
								@current-change="currentChangeHandle" :current-page="pageIndex"
								:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
								layout="total, sizes, prev, pager, next, jumper">
							</el-pagination>
						</el-col>
					</el-row>
				</el-card>
			</div>
		</el-row>
		<daily-add v-if="addVisible" ref="dailyAdd" @refreshDataList="dataList"></daily-add>
		<daily-update v-if="updateVisible" ref="dailyUpdate" @refreshDataList="dataList"></daily-update>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
import DailyAdd from "./daily-add.vue"
import DailyUpdate from "./daily-update.vue"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			pageSpan: 12,
			addVisible: false,
			updateVisible: false,
			viewPdfVisible: false,
			form: {},
			totalCount: 0,
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			dataList: [],
			fileName: '',
			disabledBatchDelete: true,
			tableSelVal: [],
			fatFileId: ''
		};
	},
	components: {
		DailyAdd,
		DailyUpdate,
		ViewPdf
	},
	mounted() {
		this.getDataList();
		this.pageIndex2 = 1;
	},
	methods: {
		//重置
		resetFormData() {
			this.fileName = '';
			this.pageIndex = 1;
			this.pageIndex2 = 1;
			this.getDataList();
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downloadHandle(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
			
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		//获取数据
		getDataList() {
			// 先获取监理日报目录的id
			this.$http({
				url: this.$store.state.httpUrl + "/business/filekindconfig/getFileKindConfigRecId",
				method: 'post',
				data: this.$http.adornData({
                    workNo: $common.getItem("workNo"),
					kindName: '监理日报',
                })
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dailyId = data.body
					this.$http({
						url: this.$store.state.httpUrl + "/business/projectworkfileinfo/list",
						method: "get",
						params: {
							workNo: $common.getItem("workNo"),
							fileKind: this.dailyId,
							fileName: this.fileName,
							limit: this.pageSize,
							page: this.pageIndex,
							type: 0
						},
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataList = data.body.records;
							this.totalPage = data.body.totalCount;
							this.pageIndex2 = this.pageIndex
						}
					});
				}
			})
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		addHandle() {
			this.addVisible = true;
			this.$nextTick(() => {
				this.$refs.dailyAdd.init();
			})
		},
		updateHandle(id) {
			this.updateVisible = true;
			this.$nextTick(() => {
				this.$refs.dailyUpdate.init(id);
			})
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let recIds = this.tableSelVal.map((item) => item.recId);
				this.$http({
					url: this.$store.state.httpUrl + `/business/projectworkfileinfo/deleteBatch`,
					method: 'post',
					data: this.$http.adornData({
						records: recIds
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
				.catch(() => { })
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recId);
			if (checkIdList.includes(row.recId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.my-pagination {
	margin-right: 20px !important;
	margin-top: -10px !important;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 160px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

</style>./diary-add.vue./daily-update.vue
